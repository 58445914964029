import React from 'react';
import './ItemCard.css'
import '../../Components/Global.css'
import { Item } from '../../Model/Item';
import { track } from '../../Model/Events';

export interface ItemCardProps {
    item: Item
}

export function ItemCard(props: ItemCardProps) {
    return (
        <div className="item-card site-width">
            <img 
                src={props.item.imageURL}
                alt={props.item.name + " poster"}
            />
            <div className='info'>
                <h1>{props.item.name + " (" + props.item.year + ")"}</h1>
                <p>{props.item.description}</p>
                <a 
                    className='source-link' 
                    href={"https://www.themoviedb.org/movie/" + props.item.tmdbId}
                > 
                    source
                </a>
            </div>
        </div>
    );
}
