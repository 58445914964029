import React from 'react';
import './ItemPage.css'
import '../../Components/Global.css'
import { track } from '../../Model/Events';

interface AddReccomendationButtonProps {
    itemId: string
}

export function AddReccomendationButton(props: AddReccomendationButtonProps) {
    return (
        <div className='add-reccomendation-button' onClick={()=>{comingSoon(props.itemId)}}>
            <h2>Add Reccomendation</h2>
        </div>
    );
}

function comingSoon(itemId: string) {
    alert("This functionality is coming soon.")
    track("add_reccomendation", {
        id: itemId
    })
}
