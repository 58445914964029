import { Helmet } from "react-helmet"

export function MainPage() {
    return (
        <Helmet>
            <title>{"IsSimilar.com - find similar movies, games and books"}</title>
            <link rel="canonical" href="https://issimilar.com" />
        </Helmet>
    );
}
