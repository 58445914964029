import React from 'react';
import './Header.css'

export function Header() {
    return (
        <header className="App-header">
            <h1>IsSimilar.com</h1>
            <h2>Similar movies, games, and books.</h2>
        </header>
    );
}
