import React, { useEffect, useState } from 'react';
import './ItemPage.css'
import '../../Components/Global.css'
import { ItemCard } from '../ItemCard/ItemCard';
import { Item } from '../../Model/Item';
import { SimilarItemCard } from '../SimilarItemCard/SimilarItemCard';
import { AddReccomendationButton } from './AddReccomendationButton';
import { useParams } from 'react-router-dom';
import { LoadingView } from './LoadingView';
import { track } from '../../Model/Events';
import { Helmet } from "react-helmet"

interface ItemPageProps {
    itemId: string
}

export function ItemPage() {
    const [item, setItem] = useState<Item>()
    const { movieId } = useParams()
    useEffect(()=>{
        loadItem(movieId!, setItem)
    },[movieId])
    useEffect(()=>{
        track("item_view", {id: movieId})
    },[movieId])
    if (item === undefined) {
        return <>
            <link rel="canonical" href={"https://issimilar.com/item/tmdb/movie/" + movieId }/>
            <LoadingView />
        </>
    }
    return (
        <div className='item-page'>
            <Helmet>
                <title>{"IsSimilar to " + item.name}</title>
                <meta name="description" content={"Movies, Games, Anime, and Books similar to " + item.name + ". " + similarNames(item)}></meta>
                <link rel="canonical" href={"https://issimilar.com/item/tmdb/movie/" + movieId }/>
            </Helmet>
            <ItemCard item={item} />
            <AddReccomendationButton itemId={item.id} />
            <h1>is similar to...</h1>
            <div className='similar-items-list'>
                {item.similarItems.map(similarItem => { 
                    return <SimilarItemCard key={similarItem.id} item={similarItem} parentId={item.id} /> 
                })}
            </div>
            <h1>other recommendations...</h1>
            <div className='similar-items-list'>
                {item.recommendations.map(similarItem => { 
                    return <SimilarItemCard key={similarItem.id} item={similarItem} parentId={item.id} /> 
                })}
            </div>
        </div>
    );
}

function similarNames(item: Item): string {
    let result = ""
    item.similarItems.forEach((item)=> {
        result = result + item.name + ". "
    })
    return result
}

async function loadItem(itemId: string, onSuccess: (item: Item) => void) {
    const response = await fetch("https://api.issimilar.com/item/tmdb?tmdb_movie_Id=" + itemId);
    if (!response.ok) { return }
    let item: Item = await response.json();
    item.tmdbId = itemId
    onSuccess(item)
}
