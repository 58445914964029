import React from 'react';
import './SimilarItemCard.css'
import '../../Components/Global.css'
import { SimilarItem } from '../../Model/Item';
import { track } from '../../Model/Events';

export interface SimilarItemCardProps {
    parentId: string
    item: SimilarItem
}

export function SimilarItemCard(props: SimilarItemCardProps) {
    let description;
    if (props.item.description !== undefined && props.item.description.length > 0) {
        description = <p className='similarities'>{props.item.description}<b> @cosmonomicon</b></p>
    } else {
        description = null
    }
    return (
        <div className="similar-item-card site-width">
            <img className='item-image'
                src={props.item.imageURL}
                alt={props.item.name}
            />
            <div className='info'>
                <a href={'/item/tmdb/movie/' + props.item.id} className="title">
                    <h2>{props.item.name + " (" + props.item.year + ")"}</h2>
                </a>
                {description}
                <div className='add-button' onClick={()=>{comingSoon(props.parentId, props.item.id)}}>
                    <h3>Add Similarities</h3>
                </div>
            </div>
        </div>
    );
}

function comingSoon(parentId:string, itemId: string) {
    alert("This functionality is coming soon.")
    track("add_similarities", {
        id: itemId,
        parentId: parentId
    })
}
