import React, { useEffect } from 'react';
import './App.css';
import { Header } from './Components/Header/Header'
import { ItemPage } from './Components/ItemPage/ItemPage';
import { Routes, Route } from "react-router-dom";
import * as events from './Model/Events'
import { isMobile } from 'react-device-detect';
import {Helmet} from 'react-helmet';
import { MainPage } from './Components/Main/Main'

function App() {
  useEffect(initAnalytics, [])
  return (
    <div className="App">
      <Helmet></Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="item/tmdb/movie/:movieId" element={<ItemPage />} />
      </Routes>
    </div>
  );
}

function initAnalytics() {
  events.init()
  events.track("app_start",{
    isMobile: isMobile,
  })
}

export default App;
